import React, { useState } from 'react';
import IncomeStep from './IncomeStep';
import CostStep from './CostStep';
import Result from './Result';

function Calculator() {
  const [step, setStep] = useState(1);
  const [income, setIncome] = useState(0);
  const [itemCost, setItemCost] = useState(0);
  const [loading, setLoading] = useState(false);

  const nextStep = () => {
    setLoading(true);
    setTimeout(() => {
      setStep(step + 1);
      setLoading(false);
    }, 500); // Simulate a delay for smooth transitions
  };

  const handleIncomeChange = (value) => setIncome(value);
  const handleCostChange = (value) => setItemCost(value);

  return (
    <div className="calculator-container bg-gray-800 rounded-lg shadow-card p-6 animate-fadeIn">
      {loading && <div className="text-center text-xl">Loading...</div>}
      {!loading && step === 1 && <IncomeStep onNext={nextStep} onChange={handleIncomeChange} />}
      {!loading && step === 2 && <CostStep onNext={nextStep} onChange={handleCostChange} />}
      {!loading && step === 3 && <Result income={income} itemCost={itemCost} />}
    </div>
  );
}

export default Calculator;
