import React from 'react';

function CostStep({ onNext, onChange }) {
  return (
    <div className="step-container">
      <h2>Step 2: Enter Item Cost</h2>
      <div className="relative">
        <input
          type="number"
          placeholder="Item Cost ($)"
          onChange={(e) => onChange(e.target.value)}
          className="border border-gray-600 bg-gray-900 text-white focus:ring-accent focus:border-accent"
        />
        <span className="text-gray-400 absolute top-0 right-3 mt-3 cursor-help" title="Enter the item's price in dollars.">
          ?
        </span>
      </div>
      <button
        onClick={onNext}
        className="bg-accent text-white mt-6 py-2 px-6 rounded-lg shadow-md hover:bg-green-500"
      >
        Next
      </button>
    </div>
  );
}

export default CostStep;
