import React from 'react';

function Result({ income, itemCost }) {
  const hoursNeeded = itemCost / income;

  return (
    <div className="result-container animate-fadeIn">
      <h2>Result</h2>
      <p className="mt-4 text-lg">
        You need to work <strong className="text-green-400">{hoursNeeded.toFixed(2)}</strong> hours to afford this item.
      </p>
    </div>
  );
}

export default Result;
