import React from 'react';
import './App.css';
import Calculator from './components/Calculator';

function App() {
  return (
    <div className="App bg-gray-900 text-white">
      <header className="p-4 flex justify-center">
        <img src="/icons/android/android-launchericon-512-512.png" alt="Hourfy Logo" className="w-12 h-12 mr-4 animate-fadeIn" />
        <h1 className="text-4xl font-bold animate-fadeIn">Hourfy - Work Time Calculator</h1>
      </header>
      <main className="container mx-auto max-w-screen-md p-6 animate-fadeIn">
        <Calculator />
      </main>
    </div>
  );
}

export default App;
